import { Stage } from '@interfaces/Stage';
import { useEffect, useState } from 'react';
import {
  Text,
  Image,
  Box,
  Flex,
  Container,
  SimpleGrid,
  Center,
  Hide,
  VStack,
  Heading,
  Button,
  Show,
  // AspectRatio,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { GameStatus } from '@interfaces/Game';
import { MdLockOutline, MdChevronRight, MdLogin } from 'react-icons/md';
import { useToast } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import logger from '@logger/logger';
import { useStages } from '@hooks/useStages';

export default HomeModule;

function HomeModule() {
  const toast = useToast();
  const router = useRouter();
  const { stages, loadNewStages, getQuizStage } = useStages();
  const [loading, setLoading] = useState<boolean>(true);
  const { data: session, status } = useSession();
  const localStorageKey = `06511df3-gbbf_get_stages`;

  function convertDateToUTC(date: Date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  const handlerMinutesGetStages = () => {
    if (typeof window === 'undefined') return;
    let logicPrizesStr = window.localStorage.getItem(localStorageKey);
    let sendRequest = false;
    let today = new Date();
    let nowTimestamp = convertDateToUTC(today).valueOf();
    if (logicPrizesStr != null) {
      let logicPrizes = JSON.parse(logicPrizesStr);
      if (logicPrizes.dt_timestamp_expires < nowTimestamp) {
        sendRequest = true;
      }
    }

    if (sendRequest || logicPrizesStr == null) {
      loadStages();
      const tomorrowMidnight = new Date(today);
      tomorrowMidnight.setDate(tomorrowMidnight.getDate() + 1);
      tomorrowMidnight.setHours(0);
      tomorrowMidnight.setMinutes(1);
      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          dt_timestamp_expires: convertDateToUTC(tomorrowMidnight).valueOf(),
        })
      );
    }
  };

  function loadStages() {
    logger.info('loadStates called');
    loadNewStages().then(() => {
      setLoading(false);
    });
  }
  useEffect(() => {
    const idInterval = setInterval(() => {
      handlerMinutesGetStages();
    }, 1 * 60 * 1000);
    return () => {
      clearInterval(idInterval);
    };
  }, []);

  useEffect(() => {
    if (status !== 'loading' && !stages) {
      setLoading(true);
      loadStages();
    }
  }, [status, stages]);

  function getActionUrl({ stage }: { stage: Stage | undefined }) {
    if (!stage || !stage?.games[0]) {
      return '/login';
    }
    let game = stage?.games[0];
    let url = `/game?id_game=${game.idGame}&id_stage=${stage.id}&type=${game.type}`;

    if (
      status === 'authenticated' &&
      (game.status === GameStatus.Locked || game.status === GameStatus.Played)
    ) {
      url = '/account/prizes';
      return url;
    } else {
      return url;
    }
  }

  function renderActionButton() {
    const stage = getQuizStage();
    if (!stage || !stage?.games[0]) {
      return (
        <Button
          rightIcon={<MdLockOutline />}
          colorScheme="orange"
          isLoading={loading}
          loadingText="Cargando"
          spinnerPlacement="start"
          onClick={() =>
            toast({
              title: 'Juego bloqueado.',
              description:
                '¡No se puede jugar, espera noticias para volver a jugar!',
              status: 'warning',
              duration: 9000,
              isClosable: true,
            })
          }
        >
          Bloqueado
        </Button>
      );
    }
    // game.
    let game = stage?.games[0];
    switch (Number(stage?.games[0].status)) {
      case GameStatus.Unlocked:
        return (
          <Button
            rightIcon={<MdChevronRight color="#14284B" fontSize={'18px'} />}
            bgColor="#FFBB38"
            fontFamily={'Inter'}
            fontWeight={500}
            textAlign="center"
            justifyContent="center"
            onClick={() => {
              router.push(`/game?id_game=${game.idGame}&id_stage=${stage.id}&type=${game.type}`);
            }}
          >
            JUGAR AHORA
          </Button>
        );
      case GameStatus.Finished:
        return (
          <Button
            rightIcon={<MdLockOutline />}
            colorScheme="orange"
            isLoading={loading}
            loadingText="Cargando"
            spinnerPlacement="start"
            onClick={() =>
              toast({
                title: 'Juego bloqueado.',
                description:
                  '¡No se puede jugar, espera noticias para volver a jugar!',
                status: 'warning',
                duration: 9000,
                isClosable: true,
              })
            }
          >
            El juego expiró en esta etapa
          </Button>
        );
      case GameStatus.Played:
        return (
          <>
            <Button
              rightIcon={<MdLockOutline />}
              bgColor="#F14971"
              color="white"
              fontWeight={400}
              isLoading={loading}
              loadingText="Cargando"
              spinnerPlacement="start"
              onClick={() =>
                toast({
                  title: '¡Espera hasta mañana!',
                  description:
                    'Vuelve mañana a jugar de nuevo. O visita tu perfil para más información.',
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                })
              }
            >
              ¿HAS JUGADO HOY?
            </Button>
            <Text
              color="white"
              fontSize={['md', 'md', 'md', 'lg']}
              align="center"
              px={[0, 10, 10]}
            >
              Vuelve mañana para continuar
            </Text>
          </>
        );
      case GameStatus.Locked:
      default:
        if (!session) {
          return (
            <Button
              leftIcon={<MdLogin />}
              colorScheme="orange"
              isLoading={loading}
              loadingText="Cargando"
              spinnerPlacement="start"
              onClick={() => {
                router.push(
                  `/game?id_game=${game.idGame}&id_stage=${stage.id}&type=${game.type}`
                );
              }}
            >
              JUGAR AHORA
            </Button>
          );
        } else {
          return (
            <Button
              rightIcon={<MdLockOutline />}
              colorScheme="orange"
              isLoading={loading}
              loadingText="Cargando"
              spinnerPlacement="start"
              onClick={() =>
                toast({
                  title: 'Juego bloqueado.',
                  description:
                    '¡No se puede jugar, espera noticias para volver a jugar!',
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                })
              }
            >
              Bloqueado
            </Button>
          );
        }
    }
  }

  const renderButtonDetails = () => {
    const stage = getQuizStage();

    if(stage == null ||stage?.games == null || stage?.games.length == 0){
      return (
        <Box
        as={motion.div}
        dragConstraints={{ left: -100, right: 100 }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition="0.05s linear"
      >
        <Center flexDir={'column'}>
          <Button
            borderWidth="3px"
            p={8}
            pl={10}
            pr={10}
            borderColor="#AF0FA9"
            _hover={{ bg: 'orange.500' }}
            _active={{ bg: 'orange.500' }}
            borderRadius={30}
            variant="outline"
            onClick={() => {
                toast({
                  title: '¡Espera hasta mañana!',
                  description:
                    'Vuelve mañana a jugar de nuevo. O visita tu perfil para más información.',
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                });
            }}
            isLoading={loading}
            loadingText="Cargando"
            spinnerPlacement="start"
            size="lg"
          >
            <Text color="white">Jugar ahora</Text>
          </Button>
        </Center>
      </Box>
      )
    }

    let textStatus = (
      <Text color="white">Jugar ahora</Text>
    );
    if (!loading && status == 'authenticated') {
      if (stage?.games[0].status == GameStatus.Played) {
        textStatus = (
          <Text color="white">
            ¿YA PARTICIPASTE
            <br />
            HOY?
          </Text>
        );
      } else if (stage?.games[0].status !== GameStatus.Locked) {
        textStatus = <Text color="white">Jugar ahora</Text>;
      }
    }
    return (
      <Box
        as={motion.div}
        dragConstraints={{ left: -100, right: 100 }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition="0.05s linear"
      >
        <Center flexDir={'column'}>
          <Button
            borderWidth="3px"
            p={8}
            pl={10}
            pr={10}
            borderColor="#AF0FA9"
            _hover={{ bg: 'orange.500' }}
            _active={{ bg: 'orange.500' }}
            borderRadius={30}
            variant="outline"
            onClick={() => {
              if (stage?.games[0].status == GameStatus.Played) {
                toast({
                  title: '¡Espera hasta mañana!',
                  description:
                    'Vuelve mañana a jugar de nuevo. O visita tu perfil para más información.',
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                });
              } else {
                router.push(getActionUrl({ stage }));
              }
            }}
            isLoading={loading}
            loadingText="Cargando"
            spinnerPlacement="start"
            size="lg"
          >
            {textStatus}
          </Button>
          {stage?.games[0].status == GameStatus.Played && (
            <Text color={'white'} textAlign={'center'}>
              Vuelva mañana para continuar
            </Text>
          )}
        </Center>
      </Box>
    );
  };

  return (
    <>
      <Box color="white">
        <Flex w="full" h="full">
          <Container
            maxW={{ base: 'full', md: '9xl', lg: '8xl' }}
            p={[5, 5, 5, 5]}
            py={[5, 5, 10, 10]}
          >
            <SimpleGrid minChildWidth="250px" spacing={[5, 5, 5, 20]}>
              <Box>
                <VStack textAlign={"center"} >
                  <Text fontSize={["35px"]} fontWeight="bold">DESAFIO FÚTBOL</Text>
                  <Text fontSize={["24px"]} fontWeight={500}>PARTICIPÁ POR <br/> GRANDES PREMIOS</Text>
                  <Image src={'/imgs/bola-icon.png'} width={{base: '50%',  md: '50%' }} />
                </VStack>
              </Box>
              <Box>
                <Hide below="sm">
                  <VStack spacing={[0, 10, 10, 20]} direction={['row', 'row']}>
                    <Heading
                      color="white"
                      fontSize={{ base: '24px', md: '32px', lg: '44px' }}
                      as="em"
                    >
                         1 Smart tv 43”
                    <br/>  1 Barra Sonido
                    <br/>  2 Camisetas Originales
                    </Heading>
                    <Text
                      color="white"
                      fontSize={{ base: '14px', md: '18px', lg: '22px' }}
                      opacity={0.8}
                    >
                      <strong>
                        Gana fabulosos premios disfrutando del Desafío Fútbol
                        2024
                      </strong>
                      <br />
                      Solo tienes que registrarte y participar en el concurso
                      cada día para aumentar tus posibilidades.
                    </Text>
                    {renderButtonDetails()}
                  </VStack>
                </Hide>
                <Show below="sm">
                  <VStack
                    spacing={10}
                    direction={['row', 'row']}
                    textAlign="center"
                  >
                    <Heading
                      color="white"
                      fontSize={{ base: '20px', md: '18px', lg: '22px' }}
                      as="i"
                    >
                      1 Smart tv 43”
                    <br/>  1 Barra Sonido
                    <br/>  2 Camisetas Originales
                    </Heading>
                    {renderButtonDetails()}
                  </VStack>
                </Show>
              </Box>
            </SimpleGrid>
          </Container>
        </Flex>
      </Box>
      <Box bgImg="url('/imgs/first-bgr1.png')">
        <Flex w="full" h="full">
          <Container
            maxW={{ base: 'full', md: '9xl', lg: '8xl' }}
            p={[5, 5, 5, 5]}
            py={[5, 5, 10, 10]}
          >
            <SimpleGrid
              minChildWidth="250px"
              columns={2}
              spacing={[5, 5, 5, 20]}
              alignItems="center"
            >
              <Box>
                <Image src={'/imgs/ImageDesafio.png'} />
              </Box>
              <Box
                minH={400}
                bgGradient="linear(to-t, #050A8F, #0F89AF)"
                borderRadius={10}
                p={[10, 10, 5, 7]}
              >
                <VStack spacing={5} alignItems="center">
                  <>
                    <Image
                      src="/icons/game-palavra.svg"
                      height={20}
                      width={20}
                    />
                    <Text color="white" fontSize={['lg', '2xl']}>
                      <strong>
                      Preguntas súper fáciles
                        {/* {!loading && getQuizStage()?.games[0].name} */}
                      </strong>
                    </Text>
                    <Text
                      color="white"
                      fontSize={['lg', 'lg', 'lg', 'xl']}
                      align="center"
                      px={[0, 10, 10]}
                    >
                     Diez preguntas por día, fácil, fácil.
                     <br/>
                      Regístrese y responda cada día para aumentar sus posibilidades de ganar los premios!
                    </Text>

                    {/* <Text
                      color="white"
                      fontSize={['md', 'lg', 'xl']}
                      align="center"
                      pt={[5, 10, 0, 10]}
                    >
                      {!loading &&
                      getQuizStage()?.games[0].user_score?.find(
                        (s) => s.type === 'coupons'
                      )?.value
                        ? `Mis numeros de la suerte: ${
                            !loading &&
                            getQuizStage()?.games[0].user_score?.find(
                              (s) => s.type === 'coupons'
                            )?.value
                          } `
                        : `¡Sin cupón!`}
                    </Text> */}
                    {renderActionButton()}
                  </>
                </VStack>
              </Box>
            </SimpleGrid>
          </Container>
        </Flex>
      </Box>
      <Box py="5">{/*do not remove */}</Box>
    </>
  );
}
